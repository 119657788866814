<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="modal_new_client"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="modalRef"
            @click="closeModal()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            novalidate="novalidate"
            @submit="onSubmitClient"
            id="new_client_form"
            :validation-schema="clientValidation"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">Create New Client</h1>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2"
                  >Client Contract Id</label
                >

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder="client contract id"
                  name="clientContractId"
                  autocomplete="off"
                  v-model="client.clientContractId"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="clientContractId" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Service Fee</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder="service fee"
                  name="serviceFee"
                  autocomplete="off"
                  v-model="client.serviceFee"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="serviceFee" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Client Name</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="client name"
                name="name"
                autocomplete="off"
                v-model="client.name"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required"># of Employees</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="# of employees"
                name="numOfEmployees"
                autocomplete="off"
                v-model="client.numOfEmployees"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="numOfEmployees" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Primary Contact</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="name"
                name="primaryContactName"
                autocomplete="off"
                v-model="client.primaryContactName"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="primaryContactName" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Primary Contact Email</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="email"
                name="primaryContactEmail"
                autocomplete="off"
                v-model="client.primaryContactEmail"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="primaryContactEmail" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <h3 class="text-muted">Primary Location</h3>
              <hr />
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Location Name</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="location name"
                name="locationName"
                autocomplete="off"
                v-model="clientLocation.locationName"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="locationName" />
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Address</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="address"
                name="streetAddress"
                autocomplete="off"
                v-model="clientLocation.streetAddress"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="streetAddress" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>Address 2</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="address 2"
                name="streetAddress2"
                autocomplete="off"
                v-model="clientLocation.streetAddress2"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">City</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder="city"
                  name="city"
                  autocomplete="off"
                  v-model="clientLocation.city"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="city" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">State</label>

                <Field
                  name="state"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="clientLocation.state"
                >
                  <option value="">Select a State...</option>
                  <option
                    v-for="item in usStates"
                    :key="item"
                    :label="item.stateName"
                    :value="item.stateAbbrev"
                  >
                    {{ item.stateName }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="state" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">Postal Code</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder="zip code"
                  name="postalCode"
                  autocomplete="off"
                  v-model="clientLocation.postalCode"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="postalCode" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="fs-6 fw-bold mb-2">Phone Number</label>

                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  placeholder="###-###-####"
                  name="phoneNumber"
                  autocomplete="off"
                  v-model="clientLocation.phoneNumber"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="phoneNumber" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin: Licensed Distributor-->
            <div v-if="userRole === 'SuperAdmin'">
              <!--begin::Input group-->
              <div
                class="d-flex flex-column mb-8 fv-row"
                v-if="!licensedDistAspNetUserId"
              >
                <h3 class="text-muted">Licensed Distributor</h3>
                <hr />
              </div>
              <!--end::Input group-->
              <!--begin::Col-->
              <div class="col-md-6 fv-row" v-if="!licensedDistAspNetUserId">
                <label class="fs-6 fw-bold mb-2">Licensed Distributor</label>

                <select
                  name="licensedDistributor"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="client.licensedDistributorAspNetUserId"
                  @change="getAssociates()"
                >
                  <option value="0">Select a Distributor...</option>
                  <option
                    v-for="item in licensedDistributors"
                    :key="item.licensedDistributorId"
                    :label="item.name"
                    :value="item.distAspNetUserId"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <!--end::Col-->
            </div>
            <!--end:Licensed Distributor-->
            <!--begin: Licensed Distributor Associate-->
            <div
              v-if="
                userRole === 'SuperAdmin' || userRole === 'LicensedDistributor'
              "
            >
              <!--begin::Input group-->
              <div
                class="d-flex flex-column mb-8 fv-row"
                v-if="!licensedDistAssocAspNetUserId"
              ></div>
              <!--end::Input group-->
              <!--begin::Col-->
              <div
                class="col-md-6 fv-row"
                v-if="!licensedDistAssocAspNetUserId"
              >
                <label class="fs-6 fw-bold mb-2"
                  >Licensed Distributor Associate</label
                >

                <Field
                  name="licensedDistributorAssociate"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="client.licensedDistributorAssociateAspNetUserId"
                >
                  <option value="0">Select an Associate...</option>
                  <option
                    v-for="item in licensedDistAssociates"
                    :key="item.licensedDistributorId"
                    :label="item.name"
                    :value="item.associateAspNetUserId"
                  >
                    {{ item.name }}
                  </option>
                </Field>
              </div>
              <!--end::Col-->
            </div>
            <!--end:Licensed Distributor Associate-->
            <!--begin::Actions-->
            <div class="text-center">
              <button
                type="reset"
                id="kt_modal_new_client_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
                @click="resetForm()"
              >
                Cancel
              </button>

              <button
                id="kt_sign_up_submit"
                ref="submitButton"
                type="submit"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface Client {
  active: boolean;
  licensedDistributorAspNetUserId: string;
  licensedDistributorAssociateAspNetUserId: string;
  currentUserAspNetUserId: string;
  locations: ClientLocation[];
  name: string;
  numOfEmployees: number;
  primaryContactEmail: string;
  primaryContactName: string;
  userRoleCreated: boolean;
  clientContractId: string;
  serviceFee: string;
}

interface ClientLocation {
  locationName: string;
  primaryLocation: boolean;
  phoneNumber: string;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  postalCode: string;
}

export default defineComponent({
  name: "new-client-modal",
  props: [
    "displayModal",
    "userRole",
    "licensedDistAspNetUserId",
    "licensedDistAssocAspNetUserId",
    "routeName",
  ],
  components: { Field, Form, ErrorMessage },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const client = ref<Client>({
      name: "",
      numOfEmployees: 0,
      primaryContactName: "",
      primaryContactEmail: "",
      active: true,
      locations: [] as ClientLocation[],
      licensedDistributorAspNetUserId: props.licensedDistAspNetUserId,
      licensedDistributorAssociateAspNetUserId:
        props.licensedDistAssocAspNetUserId,
      currentUserAspNetUserId: currentUser.id,
      userRoleCreated: props.userRole,
      clientContractId: "",
      serviceFee: "",
    });

    const clientLocation = ref<ClientLocation>({
      locationName: "",
      primaryLocation: true,
      phoneNumber: "",
      streetAddress: "",
      streetAddress2: "",
      city: "",
      state: "",
      postalCode: "",
    });

    const usStates = computed(() => store.state.usStates);

    store
      .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving licensed distributors.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const getAssociates = () => {
      store
        .dispatch(
          Actions.GET_LICENSED_DISTRIBUTOR_ASSOCIATES_DDL,
          client.value.licensedDistributorAspNetUserId
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const licensedDistAssociates = computed(() => {
      return store.getters.getLicensedDistributorAssociateDDL;
    });

    onMounted(() => {
      if (
        props.routeName === "sa-licensed-distributor-clients" ||
        props.routeName === "licensed-distributor-client-listing"
      ) {
        getAssociates();
      }
    });

    const phoneRegExp =
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

    const clientValidation = Yup.object().shape({
      clientContractId: Yup.string().required().label("Client Contract Id"),
      serviceFee: Yup.string().required().label("Service Fee"),
      name: Yup.string().required().label("Name"),
      numOfEmployees: Yup.number().min(1).required().label("Employees"),
      primaryContactName: Yup.string().required().label("Primary Contact"),
      primaryContactEmail: Yup.string().email().required().label("Email"),
      locationName: Yup.string().required().label("Location Name"),
      streetAddress: Yup.string().required().label("Address"),
      city: Yup.string().required().label("City"),
      state: Yup.string().required().label("State"),
      postalCode: Yup.string().required().label("Postal Code"),
    });

    const onSubmitClient = () => {
      client.value.locations.push(clientLocation.value);
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request

      if (props.routeName === "ld-associate-client-listing") {
        client.value.licensedDistributorAspNetUserId =
          props.licensedDistAspNetUserId;
      }

      store
        .dispatch(Actions.CREATE_CLIENT, client.value)
        .then(() => {
          Swal.fire({
            text: "Success. The new client has been created.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      client.value = {
        name: "",
        numOfEmployees: 0,
        primaryContactName: "",
        primaryContactEmail: "",
        active: true,
        locations: [],
        licensedDistributorAspNetUserId: props.licensedDistAspNetUserId,
        licensedDistributorAssociateAspNetUserId:
          props.licensedDistAssocAspNetUserId,
        currentUserAspNetUserId: currentUser.id,
        userRoleCreated: props.userRole,
        clientContractId: "",
        serviceFee: "",
      };
      clientLocation.value = {
        locationName: "",
        primaryLocation: true,
        phoneNumber: "",
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        postalCode: "",
      };
    }

    return {
      currentUser,
      client,
      clientLocation,
      usStates,
      licensedDistributors,
      licensedDistAssociates,
      phoneRegExp,
      closeModal,
      resetForm,
      modalRef,
      clientValidation,
      onSubmitClient,
      submitButton,
      getAssociates,
    };
  },
});
</script>
