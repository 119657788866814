
import { defineComponent, ref, computed, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface Client {
  active: boolean;
  licensedDistributorAspNetUserId: string;
  licensedDistributorAssociateAspNetUserId: string;
  currentUserAspNetUserId: string;
  locations: ClientLocation[];
  name: string;
  numOfEmployees: number;
  primaryContactEmail: string;
  primaryContactName: string;
  userRoleCreated: boolean;
  clientContractId: string;
  serviceFee: string;
}

interface ClientLocation {
  locationName: string;
  primaryLocation: boolean;
  phoneNumber: string;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  postalCode: string;
}

export default defineComponent({
  name: "new-client-modal",
  props: [
    "displayModal",
    "userRole",
    "licensedDistAspNetUserId",
    "licensedDistAssocAspNetUserId",
    "routeName",
  ],
  components: { Field, Form, ErrorMessage },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const client = ref<Client>({
      name: "",
      numOfEmployees: 0,
      primaryContactName: "",
      primaryContactEmail: "",
      active: true,
      locations: [] as ClientLocation[],
      licensedDistributorAspNetUserId: props.licensedDistAspNetUserId,
      licensedDistributorAssociateAspNetUserId:
        props.licensedDistAssocAspNetUserId,
      currentUserAspNetUserId: currentUser.id,
      userRoleCreated: props.userRole,
      clientContractId: "",
      serviceFee: "",
    });

    const clientLocation = ref<ClientLocation>({
      locationName: "",
      primaryLocation: true,
      phoneNumber: "",
      streetAddress: "",
      streetAddress2: "",
      city: "",
      state: "",
      postalCode: "",
    });

    const usStates = computed(() => store.state.usStates);

    store
      .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving licensed distributors.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const getAssociates = () => {
      store
        .dispatch(
          Actions.GET_LICENSED_DISTRIBUTOR_ASSOCIATES_DDL,
          client.value.licensedDistributorAspNetUserId
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const licensedDistAssociates = computed(() => {
      return store.getters.getLicensedDistributorAssociateDDL;
    });

    onMounted(() => {
      if (
        props.routeName === "sa-licensed-distributor-clients" ||
        props.routeName === "licensed-distributor-client-listing"
      ) {
        getAssociates();
      }
    });

    const phoneRegExp =
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

    const clientValidation = Yup.object().shape({
      clientContractId: Yup.string().required().label("Client Contract Id"),
      serviceFee: Yup.string().required().label("Service Fee"),
      name: Yup.string().required().label("Name"),
      numOfEmployees: Yup.number().min(1).required().label("Employees"),
      primaryContactName: Yup.string().required().label("Primary Contact"),
      primaryContactEmail: Yup.string().email().required().label("Email"),
      locationName: Yup.string().required().label("Location Name"),
      streetAddress: Yup.string().required().label("Address"),
      city: Yup.string().required().label("City"),
      state: Yup.string().required().label("State"),
      postalCode: Yup.string().required().label("Postal Code"),
    });

    const onSubmitClient = () => {
      client.value.locations.push(clientLocation.value);
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request

      if (props.routeName === "ld-associate-client-listing") {
        client.value.licensedDistributorAspNetUserId =
          props.licensedDistAspNetUserId;
      }

      store
        .dispatch(Actions.CREATE_CLIENT, client.value)
        .then(() => {
          Swal.fire({
            text: "Success. The new client has been created.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      client.value = {
        name: "",
        numOfEmployees: 0,
        primaryContactName: "",
        primaryContactEmail: "",
        active: true,
        locations: [],
        licensedDistributorAspNetUserId: props.licensedDistAspNetUserId,
        licensedDistributorAssociateAspNetUserId:
          props.licensedDistAssocAspNetUserId,
        currentUserAspNetUserId: currentUser.id,
        userRoleCreated: props.userRole,
        clientContractId: "",
        serviceFee: "",
      };
      clientLocation.value = {
        locationName: "",
        primaryLocation: true,
        phoneNumber: "",
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        postalCode: "",
      };
    }

    return {
      currentUser,
      client,
      clientLocation,
      usStates,
      licensedDistributors,
      licensedDistAssociates,
      phoneRegExp,
      closeModal,
      resetForm,
      modalRef,
      clientValidation,
      onSubmitClient,
      submitButton,
      getAssociates,
    };
  },
});
